import $t from 'o365.modules.translate.ts';
import alert from 'o365.controls.alert.ts';

export function dataURIToFile(data: string): File|undefined {
    if (!data.includes('base64')) return;
    const src = data.split('src="')[1].split('"')[0];

    const base64String = src.split(',')[1];
    const byteString = atob(base64String);
    const mimeString = src.split(':')[1].split(';')[0];

    const buffer = new ArrayBuffer(byteString.length);
    const intArray = new Uint8Array(buffer);

    for (let i = 0; i < byteString.length; i++) {
        intArray[i] = byteString.charCodeAt(i);
    }

    const blob = new Blob([buffer], { type: mimeString });

    const file = new File([blob], mimeString.replace('/', '.'), { lastModified: Date.now() })
    return file;
}

export function blobToFile(data: Array<Blob>) {
    let arr = []
    for (const file of data) {
        const mimeType = file.type;
        arr.push(new File([file], mimeType.replace('/', '.'), { type: mimeType, lastModified: Date.now() }));
    }
    return arr;
}

export function zeroBytesFilesAlert(filesList: FileList|Array<Blob>): boolean {
    let hasEmptyFile = false;
    let tempFilesList: FileList|Array<Blob>;

    tempFilesList = Array.from(filesList);
    tempFilesList.forEach((file: File|Blob) => {
        if (file.size === 0) {
            hasEmptyFile = true
        }
    })

    if (hasEmptyFile) {
        alert($t("Empty file upload is not allowed."), "danger");
    }

    return hasEmptyFile
}

export type UploadOptions = {
    files?: FileList|Array<Blob>
    onError?: Function
    onCompleted?: Function
    onProgress?: Function
    beforeUpload?: Function
    beforeUploadAsync?: (pBeforeUploadParams: object) => Promise<object|boolean>
}

export type UploadDataOptions = {
    PrimKey: string
    index: number
}